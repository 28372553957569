import lowerdev from '@/service/lowerdev';
import { useRoute } from 'vue-router';
import ClipboardJS from 'clipboard';
import domain from '@/service/domain';
import { env } from 'md-base-tools/env';
import { getMdlogisticsInfos } from "@/api/index";
import { get } from '@/utils/apiBase.js';
import { mdToast } from '../../utils/tools';
import { showSuccessToast } from 'vant';
export default {
  data() {
    return {
      placeholder: require('@assets/images/placeholder/default_1x1@2x.png'),
      copyboard: null,
      dialog_show: false,
      dialog_src: '',
      dialog_btn: [],
      dialog_used: 1
    };
  },
  components: {},
  async setup() {
    const route = useRoute();
    const order_id = route.query.order_id ? route.query.order_id : '';
    const expressNoMix = route.query.express_no_mix ? route.query.express_no_mix : '';
    const expressNo = route.query.express_no ? route.query.express_no : '';
    const expressName = route.query.express_name ? route.query.express_name : '';
    let logisticsList = [];
    let res = {};
    if (order_id) {
      res = await get('/apis/mdorder/logistics', {
        order_id: order_id,
        express_no: expressNo,
        express_name: expressName
      });
    } else {
      res = await getMdlogisticsInfos({
        express_no_mix: expressNoMix || expressNo,
        express_name: expressName
      });
    }
    if (res.status !== 0) return mdToast(res.message || '未查询到物流信息');
    logisticsList = res.data.logistics_list;
    return {
      expressNoMix,
      expressNo,
      expressName,
      logisticsList
    };
  },
  watch: {},
  computed: {
    isShow() {
      return !(env.isModianIos() || env.isModianAndroid() || env.isWxApp() || env.isWxApp() || env.isWeixin());
    }
  },
  mounted() {},
  methods: {
    dialog(index) {
      this.index = index;
      this.dialog_src = this.orderInfo.goods_info[index].qr_code;
      this.dialog_used = this.orderInfo.goods_info[index].use_qr;
      this.dialog_show = true;
    },
    close_dialog() {
      this.dialog_show = false;
    },
    copy(i) {
      document.getElementsByTagName('input')[0].select();
      try {
        if (document.execCommand('copy', false, null)) {
          showSuccessToast('复制成功');
        } else {
          // IOS不支持时候重新初始化Clipboard
          var copyTarget = this.$refs.copyboard[i];
          var copyboard = new ClipboardJS(copyTarget, {
            text(copyTarget) {
              return copyTarget.getAttribute('copyData');
            }
          });
          copyboard.on('success', () => {
            showSuccessToast('复制成功');
          });
        }
      } catch (err) {
        mdToast(err);
      }
    },
    jumpNft(item) {
      location.href = `${domain.wap}/digital/detail/${item.nft_stock_hash}.html`;
    },
    dropBack() {
      if (history.length > 1) {
        this.$router.back(-1);
      } else {
        lowerdev.openUrl();
      }
    }
  }
};